import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import styled from "styled-components"

function Blog() {
  return (
    <Layout>
      <SEO title="Blog" />
      <Wrapper>
        <Bg1>
          <ContentHeaderWrapper> Blog </ContentHeaderWrapper>
        </Bg1>
        <Bg1>
          <ContentBodyWrapper>
            <Header>Medium</Header>
            <Body>
              Worked as designer for 12 years - Check out my Linkedin Page for
              detail. Married my wife who is also designer and motivates me to
              become better person everyday. Father of 2 kids, Go Hawks. Jesus
              is my savior I value the People the most. whether that is
              customer, collegues I work with. We all have stories, and I would
              love to learn from you and build meangful thing together to make
              the world better place. Contact me at kennyhong@outlook.com
            </Body>
          </ContentBodyWrapper>
        </Bg1>
      </Wrapper>
    </Layout>
  )
}

export default Blog

const Wrapper = styled.div`
  width: 100%;
`

const ContentHeaderWrapper = styled.div`
  width: 100%;
  padding: 86px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 72px;
  color: #000;
  padding-bottom: 86px;
  max-width: 1000px;
  margin: 0 auto;
`

const ContentBodyWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`

const Bg1 = styled.div`
  width: 100%;
  padding: 86px 200px;

  @media (max-width: 944px) {
    padding: 24px;
  }
`

const Header = styled.div`
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  color: #000;
  margin-bottom: 24px;
`

const Body = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  /* Text */

  color: #000000;
  margin-bottom: 24px;
`
